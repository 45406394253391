<template>
  <svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.78743 1.07949C8.50315 0.790235 8.04223 0.790235 7.75794 1.07949L4.93001 3.95684L2.10208 1.07949C1.81779 0.790237 1.35688 0.790238 1.07259 1.07949C0.788303 1.36875 0.788304 1.83772 1.07259 2.12697L4.35783 5.46963C4.37459 5.49094 4.39268 5.51154 4.4121 5.53129C4.55496 5.67666 4.74244 5.74897 4.92969 5.74823C5.11716 5.74913 5.3049 5.67682 5.44793 5.53129C5.46735 5.51153 5.48544 5.49093 5.50221 5.46961L8.78743 2.12697C9.07172 1.83772 9.07172 1.36874 8.78743 1.07949Z"
      fill="#333333"
    />
  </svg>
</template>
